.storage {
  background-image: url('../../../assets/downloadBanner/storage_web_bg.png');
}
.storage-mobile {
  background-image: url('../../../assets/downloadBanner/storage_mobile_bg.png');
}
.cong_download_modal.storage {
  width: 100%;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background-position: right top;
  box-shadow: 0 0 30px 4px #dde5e8;
}

.cong_download_modal.storage p {
  font-family: 'Poppins-Bold';
  font-weight: bold;
  margin-top: 12px;
}
.cong_download_modal.storage p.pri_text {
  color: #5d00ff;
  margin-right: 16px;
  margin-top: 6px;
}
.storage-custom-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
}

.cong_download_modal.storage-mobile {
  width: 100%;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background-position: right top;
  border-radius: 0;
  top: 92%;
  padding: 17px 15px 20px;
  bottom: unset;
  box-shadow: 0 0px 10px 0px #dde5e8;
}
.cong_download_modal.storage-mobile p {
  margin: 0;
  font-family: 'Poppins-Bold';
  font-size: 17px;
  color: #1c1939;
  letter-spacing: 0;
  line-height: 24px;
}
.cong_download_modal.storage-mobile span.pri_text {
  color: #5d00ff;
  margin-left: 0;
}
.cong_download_modal.storage-mobile {
  bottom: 0;
  top: auto;
  transform: none;
  left: 0;
  background-size: contain;
}

@media (max-width: 375px) {
  .cong_download_modal.storage-mobile p {
    font-size: 14px;
  }
}
