.width-full {
  width: 100%;
}

.go-back-button {
  padding: 10px 18px 10px 18px;
  border-radius: 8px;
  background-color: #f4f2ec;
  color: #424242;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  border: 1px solid #424242;
}

.error {
  border: 1px solid red;
}

.dark-gray-border {
  border-radius: 8px !important;
  border: 1px solid #424242 !important;
}

.dark-gray-text {
  color: #424242 !important;
}

input:focus {
  outline: 2px solid #ccc7b5;
}
.helper {
  margin-top: 2px;
  margin-bottom: 0px;
  margin-left: 5px;
  color: gray;
  font-size: smaller;
}
