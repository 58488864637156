@import '../../../styles/colors.scss';
@import '../../../styles//mediamixins.scss';

.continue-purple-button {
  background-color: $blue !important;
  color: white !important;
  text-transform: none !important;
  margin-left: 10px !important;
  border-radius: 25px !important;
  min-width: 150px !important;
  min-height: 50px !important;
  box-shadow: 0 10px 40px 0 $lightgrayblue !important;
}

.continue-purple-button:disabled {
  background-color: #e4e7ed !important;
  box-shadow: unset !important;
  color: $lightgrayblue !important;
}

.continue-purple-button:hover {
  background-color: $white !important;
  color: $blue !important;
}

@include media('<=tablet') {
  .continue-purple-button {
    min-height: 40px !important;
  }
}
