@import './colors.scss';

.red {
  color: red;
}

.white {
  color: $white;
}

.powderblue {
  color: $powderblue;
}

.backbuttongray {
  color: $backbuttongray;
}

.blue-svg g {
  fill: $blue;
}

.purplelight {
  color: $purplelight;
}

.lightgrayblue-stroke-svg g {
  stroke: $lightgrayblue;
}

.powderblue-svg g {
  fill: $powderblue;
}

.tealblue-svg g {
  fill: $powderblue;
}

.powderblue-path-svg path {
  fill: $powderblue;
}

.blue-svg-path path {
  fill: $blue;
}

.white-svg-path path {
  fill: $white;
}

.yellow-svg-path path {
  fill: $yellow;
}

.blue-svg-stroke g {
  stroke: $blue;
}

.bluegrey {
  color: $gray;
  opacity: 0.6;
}

.lightteal {
  color: $lightteal;
}

.black {
  color: $black;
}

.blue {
  color: $blue;
}

.skyblue {
  color: $skyblue;
}

.green {
  color: $green;
}

.glowgreen {
  color: $glowgreen !important;
}

.grey {
  color: $gray;
}

.gray {
  color: $gray !important;
}

.dark-grey {
  color: $dark-grey !important;
}

.lightgrayblue {
  color: $lightgrayblue !important;
}

.darkbluegray {
  color: $darkbluegray;
}

.tealblue {
  color: $tealblue !important;
}

.placeholder-text {
  color: $placeholder-text;
}

.yellow {
  color: $yellow;
}

.secondaryblack {
  color: $secondaryblack;
}

.lightorange {
  color: $lightorange;
}

.royalblue {
  color: $royalblue;
}

.red {
  color: $red;
}

.eggplantpurple {
  color: $eggplantpurple;
}
