@import '../../../styles/colors.scss';
@import '../../../styles//mediamixins.scss';

.continue-button {
  background-color: $green !important;
  color: white !important;
  text-transform: none !important;
  border-radius: 25px !important;
  min-width: 150px !important;
  min-height: 50px !important;
  box-shadow: 0 10px 40px 0 $greenbackground !important;
}

.continue-button-next {
  background-color: $green !important;
  color: white !important;
  text-transform: none !important;
  margin-left: 10px;
  border-radius: 25px !important;
  min-width: 150px !important;
  min-height: 50px !important;
  box-shadow: 0 10px 40px 0 $greenbackground !important;
}

.continue-button:disabled {
  background-color: #e4e7ed !important;
  box-shadow: unset !important;
  color: $lightgrayblue !important;
}

.continue-button:hover {
  background-color: $glowgreen !important;
}

.continue-button-mobile {
  min-width: 100px !important;
  font-weight: bold !important;
  margin-right: 5% !important;
}

.payment-newFlow-button-mobile {
  font-weight: bold !important;
  margin-right: 5% !important;
}

@include media('<=tablet') {
  .continue-button {
    min-height: 40px !important;
  }
}

@media (max-width: 320px) {
  .payment-newFlow-button-mobile {
    min-width: 100px !important;
  }
}
