@import '../../styles/colors.scss';

.discount-container {
  background-color: $discountbluebackground;
  padding: 12px;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: space-between;
  // border-radius: 4px;
}

.select-discount-container {
  background-color: $discountbluebackground;
  padding: 6px;
  padding-left: 6px;
  padding-right: 6px;
  justify-content: space-between;
  margin-left: 8px;
  margin-bottom: 3px;
  // border-radius: 4px;
}

.yes-no-discount {
  background-color: $discountbluebackground;
  padding: 0px;
}

.annual-tag-cont {
  background-color: rgba(2, 191, 137, 0.2) !important;
  color: #07815e !important;
}

.green-svg-stroke g {
  stroke: #07815e;
}
