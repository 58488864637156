@font-face {
  font-family: 'AvenirLTPro';
  src: url(./assets/fonts/AvenirLTProBlack.woff);
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url(./assets/fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url(./assets/fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: 'Avenir-Book';
  src: url(./assets/fonts/AvenirLTProBook.woff);
}

html {
  height: 100%;
  padding: 0;
  margin: 0;
}
.avenir-book {
  font-family: 'Avenir-Book' !important;
}

.poppins-bold {
  font-family: 'Poppins-Bold' !important;
}

.avenir-lt-pro {
  font-family: 'AvenirLTPro' !important;
}

.poppins-regular {
  font-family: 'Poppins-Regular' !important;
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-family: 'Poppins-Regular';
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.MuiContainer-root {
  flex: 1 0 auto !important;
}

@media only screen and (min-width: 768px) {
  body {
    background-image: url('assets/buttom-cover.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    background-attachment: fixed;
  }

  body.nobackground {
    background-image: unset;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    background-attachment: fixed;
  }
}

/* in order to remove that annoying up and down arrows on input with type="number" */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}
