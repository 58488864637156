.congrats_title {
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #222934;
}

.congrats_page {
  height: 100%;
  margin: auto;
  position: relative;
  padding-bottom: 200px;
}
@media (max-width: 991px) {
  .congrats_page > div {
    width: 100%;
    margin: 0 auto;
  }
}
@media (max-width: 600px) {
  .congrats_page {
    width: 100% !important;
  }
  .congrats_page > div {
    width: 100%;
    margin: 0 auto;
  }
}
