@import '../../styles/colors.scss';

.camera_overlay {
  // background: linear-gradient(to bottom, $blue, #8e75da);
  width: 100%;
  padding: 10px;
  display: unset !important;
  // top: 5vh;
  // height: 10vh;
  position: absolute;
  z-index: 9999999;
  // opacity: 0.9;
}

#container-circles {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 50px;
}

video {
  max-height: 300px !important;
  position: absolute;
}

#rectangle {
  position: absolute;
  padding: 70px 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 2147483647;
  width: 70%;
  height: 30%;
  background: transparent;
  top: 0px;
  border: solid white;
  border-radius: 1em;
  border-width: 3px;
}

.subtextoverlay {
  margin-top: 6px;
}

@media (orientation: landscape) {
  .subtextoverlay {
    display: none;
  }

  .logo-on-camera {
    visibility: hidden;
    height: 0;
    width: 0;
  }

  .subtextoverlay {
    margin-left: 6px;
  }

  .camera_overlay {
    top: 0;
    height: 50px;
    width: fit-content;
    // margin: auto;
    display: flex !important;
    left: 30px;
    // right: 0;
  }

  video {
    width: 80vw !important;
    object-fit: cover;
  }

  .react-html5-camera-photo {
    text-align: left !important;
  }

  #container-circles {
    width: 20vw;
    // right: 40px !important;
    left: unset !important;
    top: 0px;
    bottom: 0px !important;
  }

  #rectangle {
    padding: 40px;
  }

  .pred {
    right: auto;
  }
}
