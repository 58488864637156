.video-wrapper {
  position: relative;
  width: 100% !important;
  height: 100% !important;
  margin-left: -110px !important;
  margin-top: 20px !important;
  object-fit: contain;
}

.video-wrapper-mobile {
  position: relative;
  width: 330px !important;
  height: 330px !important;
  margin-top: -30px !important;
}

@media (max-width: 360px) {
  .video-wrapper-mobile {
    position: relative;
    width: 320px !important;
    height: 320px !important;
  }
}
