@import '../../../styles/mediamixins.scss';
@import '../../../styles/colors.scss';

.page {
  background-color: #f4f2ec;
  height: 100vh;
  z-index: 1;
  color: #344054;
  display: flex;
  margin: 0;
}
.markel-login-bg-image {
  background-image: url('../../../assets/markel_login_image.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.markel-login-bg-image-mobile {
  background-image: url('../../../assets/markel_login_image_mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

@media (min-width: 960px) {
  .markel-login-bg-image {
    height: auto !important;
  }
}

.background-image {
  height: 100%;
  width: 100%;
}

.background-image-mobile {
  height: 380px;
  width: 375px;
}

.backgroundColor {
  background-color: #f4f2ec;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.center {
  align-self: center;
}

.info-section {
  height: 100%;
  width: 70%;
  margin: auto;
  display: flex;
}

.info-section-width {
  width: 50%;
}

.info-section-width-mobile {
  width: 90%;
}

.margin-left-35 {
  margin-left: 35px;
}

.margin-right-35 {
  margin-right: 35px;
}

.invisible {
  visibility: hidden;
}

.radius-100 {
  border-radius: 100px;
}

.column {
  flex-flow: column;
}

.logo {
  height: 27px;
  margin-left: 8px;
}
.logo-mobile {
  height: 21px;
  margin-left: 8px;
}

.height-15 {
  height: 15px;
}

.divider {
  width: 100%;
  height: 2px;
  color: #f4f2ec;
}

//because this style class exists, i need to change the name because the  "!important" efected all the app
.flex-row-markel-login {
  display: flex;
  flex-flow: row !important;
}

.space-between-markel-login .width-35 {
  width: 35% !important;
}

.width-full {
  width: 100%;
}

.width-half {
  width: 50%;
}

//because this style class exists, i need to change the name because the  "!important" efected all the app
.space-between-markel-login {
  justify-content: space-between !important;
}

.poppins-regular {
  font-family: 'Poppins-Regular' !important;
}

.flex-end {
  align-self: flex-end;
}

.gap-10 {
  gap: 10px;
}

.Pompiere {
  font-family: 'Pompiere';
}

.full-margin {
  margin-bottom: 16px;
  margin-top: 16px;
  margin-left: 35px;
  margin-right: 35px;
}

.text-center {
  text-align: center;
}

.login-options-container {
  display: flex;
  padding: 8px 0px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.login-option {
  display: flex;
  justify-content: center;
  padding: 11px 0px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 6px;
  border: #ece9dc;
  background: #ece9dc;
  color: #938966;
}

.login-option:hover {
  background: #dfdac6;
  cursor: pointer;
}

.login-option-selected {
  background: #ccc7b5;
}
.login-option-logo-dselected {
  stroke: #344054;
  stroke-width: 1.66667;
}
.login-option-logo-selected {
  stroke: #ffffff;
  stroke-width: 1.66667;
}

.login-option-logo {
  display: block;
}

.icon-gray {
  stroke: #667085;
  stroke-width: 1.66667;
}

.submit-button {
  padding: 10px 18px 10px 18px;
  border-radius: 8px;
  background-color: #424242;
  color: white;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  border: 0;
}

.error-container {
  border: 1px solid #f8d2d2;
  border-radius: 8px;
  background-color: #fceded;
  padding: 8px 10px 8px 10px;
}
.error-title {
  color: #cb4444;
}
.error-message {
  color: #6f2525;
}

.underline-text {
  text-decoration: underline;
}

.underline-text:hover {
  cursor: pointer;
}

.gray {
  color: #344054;
}

.light-gray {
  color: var(--Gray-400, #98a2b3);
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.text-xs {
  font-size: 12px;
}

.text-sm {
  font-size: 14px;
}

.text-base {
  font-size: 16px;
}

.text-large {
  font-size: 18px;
}

.text-2xl {
  font-size: 24px;
}

.text-3xl {
  font-size: 30px;
}

.text-enormous {
  font-size: 150px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.margin-auto {
  margin: auto;
}

.footer {
  clear: both;
  position: relative;
  bottom: 0;
  text-align: center;
}

.margin-top-80 {
  margin-top: 80px;
}

.flex-end {
  align-self: flex-end;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.padding-top-16 {
  padding-top: 16px;
}

.padding-right-16 {
  padding-right: 16px;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}
.margin-top-8 {
  margin-top: 8px;
}

.margin-top-6 {
  margin-top: 6px;
}

.margin-bottom-6 {
  margin-bottom: 6px;
}

.padding-bottom-24 {
  padding-bottom: 24px;
}

.divider-text {
  color: #b0a788;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  align-self: center;
  width: 30%;
}

.justify-content-center {
  justify-content: center;
}

@media (max-width: 327px) {
  .login-option-logo {
    display: none !important;
  }
}
