@import '../../styles/colors.scss';

.odo-pick-title {
  background-color: $blue;
}

.odo-number {
  color: $blue;
  font-size: 2.5em;
}

.odo-image {
  height: 25%;
  width: 500px;
}

.item_list {
  padding-top: 0px;
  padding-bottom: 0px;
  width: 90%;
}

@media (max-width: 768px) {
  .odo-image {
    width: 100%;
  }

  .odometer-background {
    background-image: url('/assets/fill-2.png');
    background-size: 100%;
    background-position: right bottom;
    background-repeat: no-repeat;
  }

  .odo-number {
    color: $blue;
    font-size: 3em;
    margin-right: 3vw;
  }
  .odometer-btn {
    border-radius: 25px;
    height: 40px;
    width: 100px;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    background-color: #0ce0a3;
    color: white;
    font-weight: bold;
    border: 0;
    font-size: 14pt;
    align-items: center;
    text-decoration: none;
    line-height: 40px;
  }
  .odometer-done-text {
    text-align: center;
  }
}
