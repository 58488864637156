.cong_download_modal {
  width: 500px;
  margin-left: -110px;
  padding: 17px 26px 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 35px;
  background-size: cover;
  /* // background-position: bottom right; */
  background-position: initial;
  background-repeat: no-repeat;
}
.cong_download_modal p {
  margin: 0;
  font-family: 'Poppins-Bold';
  font-size: 23.4px;
  color: #1c1939;
  letter-spacing: 0;
  line-height: 33px;
}
.cong_download_modal p.pri_text {
  color: #5d00ff;
}
.download-modal-btns {
  margin-top: 14px;
  display: flex;
  align-items: center;
}

img.d_arrow {
  width: 26px;
  height: auto;
  margin-right: 18px;
}
img.google_play_store {
  width: 135px;
  height: auto;
  margin-right: 23px;
  cursor: pointer;
}
img.App_store {
  width: 118px;
  height: auto;
  cursor: pointer;
}
.cong_download_modal.cng-dl-mobile-modal {
  background-position: top right;
  padding: 19px 24px 21px;
  width: 100%;
  margin-left: 0;
}
span.pri_text {
  color: #5d00ff;
  margin-left: 6px;
}
.cong_download_modal.cng-dl-mobile-modal p {
  font-size: 18px;
  line-height: 30px;
  padding-right: 27%;
}
.cong_download_modal.cng-dl-mobile-modal .download-modal-btns {
  flex-wrap: wrap;
}
.cong_download_modal.cng-dl-mobile-modal .download-modal-btns img.d_arrow {
  transform: rotate(90deg);
  width: 20.53px;
  margin-bottom: 18px;
}
.banner-app-button {
  display: flex;
  align-items: center;
  width: 100%;
}

.banner-app-button img.google_play_store {
  margin-right: 17px;
}

@media (min-width: 768px) {
  .cong_download_modal.cng-dl-mobile-modal p {
    padding-right: 90px;
  }
}
/* Policy management */
.cong_download_modal.cng-dl-mobile-modal.policy-management-banner p {
  padding-right: 30%;
}
@media only screen and (min-width: 360px) and (max-width: 360px) {
  .cong_download_modal.cng-dl-mobile-modal.policy-management-banner p {
    padding-right: 28%;
  }
}
.cong_download_modal.cng-dl-mobile-modal.policy-management-banner {
  background-image: url('../../assets/downloadBanner/policy_mobile_bg.png');
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.2);
  padding: 21px 20px 21px;
}
.MuiGrid-root.MuiGrid-container .policy-grid {
  display: flex;
  padding-bottom: 0;
}
.MuiGrid-root.MuiGrid-container .policy-grid .cong_download_modal.cng-dl-mobile-modal.policy_downalod_banner {
  margin-top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.MuiGrid-root.MuiGrid-container .policy-grid .cong_download_modal.cng-dl-mobile-modal.policy_downalod_banner .download-modal-btns {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
}
/*  */

/* Odometer Success Page Banner */
.cong_download_modal.cng-dl-mobile-modal.odometer-modal {
  background-image: url('../../assets/downloadBanner/odoMeter_mobile_bg.png');
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.2);
  padding: 21px 20px 21px;
}

.cong_download_modal.cng-dl-mobile-modal.odometer-modal p {
  font-weight: bold;
  line-height: 26px;
  padding-right: 43px;
  color: #fff;
}

.cong_download_modal.cng-dl-mobile-modal.odometer-modal p span.pri_text {
  color: #feae46;
  margin-left: 0;
}

.cong_download_modal.cng-dl-mobile-modal.odometer-modal .download-modal-btns {
  margin-top: 17.5px;
}

.cong_download_modal.cng-dl-mobile-modal.odometer-modal .download-modal-btns img.d_arrow {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .cong_download_modal.cng-dl-mobile-modal.odometer-modal p {
    padding-right: 150px;
  }
}
/* @media (orientation: landscape) {     
  .cong_download_modal.cng-dl-mobile-modal.odometer-modal {
      visibility: hidden;
  }
} */
/*  */
