//odometer success text-icon

.odometer-success-icon {
  height: 132px;
}
.odometer-success-text {
  font-size: 26px;
  line-height: 32px;
  font-weight: bold;
  font-family: 'Poppins-bold';
  margin-top: 21px;
}
.odometer-btn {
  margin-bottom: 16px;
}
.preview-content.odometer-success-content {
  padding-top: 100px;
}
.odometer-btn {
  width: auto;
  padding: 12px 22px;
  box-sizing: border-box;
  height: 50px;
  line-height: 50px;
  display: flex;
  align-items: center;
}
.odometer-done-text {
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
  font-family: 'Poppins-bold';
}
.priview-background.odometer-success-main {
  overflow: auto;
  display: block !important;
  height: auto;
  flex: none !important;
  min-height: 100% !important;
}
@media (max-width: 768px) {
  .odometer-btn {
    width: auto !important;
    padding: 12px 22px;
    box-sizing: border-box;
    height: 50px !important;
    line-height: 50px !important;
    display: flex;
    align-items: center;
  }

  .odometer-done-text {
    font-size: 18px;
    line-height: 20px;
    font-weight: bold;
    font-family: 'Poppins-bold';
  }
}
//
