@import '../../styles/variables.scss';
@import '../../styles/colors.scss';

.button {
  cursor: pointer;
  @include width-size-map($button-width);
  height: 47px;
  border-radius: 23.5px;
  border: solid 1px $blue;
  background-color: $blue;
  box-shadow: 0 10px 40px $buttonshadow;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 180px !important;

  .btn-text {
    @include font-size-map($secondary-font-size);
    color: $white;
  }
}

.flat {
  box-shadow: unset !important;
}

.button:active {
  border: solid 1px $buttonhover;
  background-color: $buttonhover;
}

.button:hover {
  border: solid 1px $buttonhover;
  background-color: $buttonhover;
  color: white !important;
  border-color: $buttonhover !important;
}

.button-disabled {
  @include width-size-map($button-width);
  height: 47px;
  border-radius: 23.5px;
  border: solid 1px #e4e7ed;
  background-color: #e4e7ed;
  box-shadow: unset !important;
  .btn-text {
    color: $lightgrayblue;
  }
  min-width: 180px !important;
}

.blue-outlined-button {
  background-color: $white !important;
  color: $skyblue !important;
  font-weight: bold !important;
  min-width: 100px;
  min-height: 50px;
  border-radius: 25px !important;
  text-transform: none !important;
  border: solid 1px $skyblue !important;
}

.no-text-transform {
  text-transform: none !important;
}

.quote-button-size {
  min-width: 150px !important;
  min-height: 50px !important;
}

.with-blue-border {
  border: 1px solid $blue !important;
  border-radius: 40px !important;
  padding: 8px !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
  // width: 130px;
}

.button-green-newfill {
  cursor: pointer;
  @include width-size-map($button-width);
  height: 47px;
  border-radius: 23.5px;
  background-color: $green;
  border: solid 1px $green;
  box-shadow: 0 10px 40px 0 rgba(0, 255, 136, 0.55);
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 180px !important;

  .btn-text {
    @include font-size-map($secondary-font-size);
    color: $white;
  }
}

.button-green-newfill:active {
  border: solid 1px #0aab7d;
  background-color: #0aab7d;
}

.button-green-newfill:hover {
  border: solid 1px #0ce0a3;
  background-color: #0ce0a3;
}

.red-button {
  border-radius: 8px;
  border: 1px solid var(--Error-600, #cb4444);
  background: var(--Error-500, #e24c4c);
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  color: white;
}

.red-button:hover {
  cursor: pointer;
}

.purple-button {
  border-radius: 8px;
  border: 1px solid var(--Error-600, #5d00ff);
  background: var(--Error-500, #5d00ff);
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  color: white;
}

.purple-button:hover {
  cursor: pointer;
}

.white-button {
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Gray-700, #344054);
  background: var(--Base-White, #fff);
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.white-button:hover {
  cursor: pointer;
}

.button-darkblue-newfill {
  cursor: pointer;
  @include width-size-map($button-width);
  height: 47px;
  border-radius: 23.5px;
  background-color: $darkblue;
  border: solid 1px $darkblue;
  box-shadow: 0 10px 40px $darkblue;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 180px !important;

  .btn-text {
    @include font-size-map($secondary-font-size);
    color: $white;
  }
}

.button-darkblue-newfill:active {
  border: solid 1px $buttonactive;
  background-color: $buttonactive;
}

.button-darkblue-newfill:hover {
  border: solid 1px $buttonhover;
  background-color: $buttonhover;
}

.circle-border {
  border: 1px solid $blue;
  border-radius: 30px;
  padding: 12px;
}

.outlined {
  border-radius: 6px !important;
  font-weight: bold;
}

.gray-button {
  background-color: white;
  color: $blue !important;
  font-weight: bold;
  border-color: #5b6b82 !important;
}

.blue-bold-button {
  background-color: white;
  color: $blue !important;
  font-weight: bold;
  border-color: $blue !important;
  border-width: 2px;
}

.unfilled-bold-button:hover {
  background-color: white;
  color: $blue !important;
  font-weight: bold;
  border-color: $blue !important;
  border-width: 2px;
}
