$grey: #77869e;

$gray: #77869e;

$dark-grey: #606060;

$lightteal: var(--lightteal);

$verylightteal: var(--verylightteal);

$verylightgray: #f3f8ff;

$lightgray: #dfe7f5;

$bluegrey: var(--bluegrey);

$lightgraybox: var(--lightgraybox);

$lightgrayblue: #9fadc5;

$darkbluegray: #9498a0;

$black: #030c22;

$secondaryblack: #242f40;

$blue: var(--blue);

$skyblue: #3986e9;

$powderblue: var(--powderblue);

$green: #00c48c;

$glowgreen: #0ce0a3;

$greenbackground: rgba(0, 255, 136, 0.55);

$darkblue: var(--darkblue);

$darkskyblue: var(--darkskyblue);

$discountbluebackground: #eef4ff;

$backbuttongray: #9cafd0;

$iceblue: #dbedfb;

$tealshadow: #dbe5f1;

$palegreytwo: #fbfdff;

$palegreythree: #f2f5fc;

$white: #ffffff;

$tealblue: #89b6de;

$placeholder-text: #8898b5;

$eggplantpurple: #5743af;

$purple: #4117ab;

$purplelight: #e3e5ff;

$yellow: #ffd35e;

$orange: #ff8203;

$lightorange: #feb04b;

$blueactive: #0058da;

$bluehover: #4199ff;
$royalblue: #0076ff;
$red: #c40000;

$secondary: var(--secondary);

$buttonactive: var(--buttonactive);

$buttonhover: var(--buttonhover);

$buttonshadow: var(--buttonshadow);

:export {
  blue: $blue;
  lightteal: $lightteal;
  bluegrey: $bluegrey;
  verylightteal: $verylightteal;
  darkblue: $darkblue;
  primary: $blue;
  secondary: $secondary;
  powderblue: $powderblue;
  purple: $purple;
  yellow: $yellow;
  buttonactive: $buttonactive;
  buttonhover: $buttonhover;
  buttonshadow: $buttonshadow;
}
