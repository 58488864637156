.congrats_title {
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #222934;
}

.congrats_page {
  height: 100%;
  margin: auto;
}

.sign_position {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: white;
  height: 140px;
  width: 100%;
}

@media (max-width: 600px) {
  .congrats_page {
    width: 80%;
  }
}
