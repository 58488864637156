@import '../../styles/colors.scss';

#outer-circle {
  position: absolute;
  left: -37px;

  height: 75px;
  width: 75px;

  /*
      opacity of 0.4
    */
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50%;

  z-index: 1;
}

#inner-circle {
  position: absolute;
  left: 50%;
  top: 38px;

  height: 44px;
  width: 44px;

  background: white;
  border-radius: 90px;

  /*
     Offset the position correctly with
     minus half of the width and minus half of the height
    */
  margin: -22px 0px 0px -22px;

  /*
      On the top of outer-circle
    */
  z-index: 2;
}

#inner-circle.is-clicked {
  height: 38px;
  width: 38px;
  margin: -19px 0px 0px -19px;
}

.circle {
  height: 55px;
  width: 55px;
  z-index: 2;
  border-radius: 80px;
  background-color: white;
  // border: 4px solid #8080806b;
}

.ring {
  border: 3px solid white; /* Light grey */
  border-radius: 90px;
  width: 71px;
  height: 70px;
  position: absolute;
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid gray; /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  position: absolute;
}

.green-circle {
  border: 4px solid $glowgreen; /* Light grey */
  // border-top: 4px solid gray; /* Blue */
  border-radius: 50%;
  width: 76px;
  height: 76px;
  animation: enlarge 1s linear infinite;
  position: absolute;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes enlarge {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
