@import '../../styles/colors.scss';

.priview-background {
  background: linear-gradient(136deg, #09203f 0%, #537895 100%);
  height: 100%;
  position: relative;
}

.logo-fit {
  height: 50px;
}

.preview-title-top {
  padding: 20px;
}

.preview-title-bottom {
  margin-top: 20px;
  margin-bottom: 30px;
}

.preview-image {
  object-fit: contain;
  width: 96%;
}

.preview-content {
  z-index: 5;
  justify-content: space-around;
  position: relative;
  opacity: 1;
}

.priview-background2 {
  height: 100%;
  position: relative;
  z-index: 1;
  //opacity: 0.74;
  //background-color: rgba(0, 0, 0, 0.51);
  // background-color: rgba(0, 0, 0, 0.26);
  background-image: url('/assets/fill-1.png');
  background-size: 95%;
  background-position: top left;
  background-repeat: no-repeat;
}

.img-ok-btn {
  background-color: #2dd090 !important;
}

.img-no-btn {
  background-color: red !important;
}

.img-retry-btn {
  background-color: #9993c2 !important;
}
