@import '../../styles/colors.scss';
@import '../../styles/mediamixins.scss';

.no-policy-shield {
  width: 40px;
  height: 70px;
}

.no-policy-shield g {
  fill: $gray;
}

.policy-list-card-title {
  min-width: 412px;
  padding-right: 80px;
}
.policy-list-card-title.policy-list-card-title-padding {
  padding-right: 20px;
}

.policy-list-card-title-small {
  min-width: 340px;
  padding-right: 65px;
}

.policy-card-list-shield {
  width: 35px !important;
  height: 48px !important;
}

.policy-card-item-button {
  height: 81px;
  width: 100%;
  border-radius: 7px;
  border: solid 1px $blue;
  justify-content: center;
  display: flex;
  align-items: center;
}

.policy-card-item-button:hover {
  color: white;
  background-color: $blue;
}

.policy-card-item-button.enabled {
  background-color: $blue;
  color: white;
}

.policy-card-item-button.card-button-left {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.policy-card-item-button.card-button-right {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.policy-number-rectangle {
  width: 300px;
  //height: 56px;
  // margin: 24px 442px 23px 4px;;
  padding: 15px 16.5px 11px 21.5px;
  border-radius: 5px;
}

.green_button {
  width: 260px;
  height: 50px;
  margin: 19px 0 0 1px;
  padding: 11px 15px 14px 15px;
  border-radius: 7px !important;
  background-color: #00c48c;
  color: #eeeeef;
}
.title-style-mobile {
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: 'Poppins-Bold' !important;
}

.shiled_style {
  margin-right: 10px;
  margin-top: 25px;
}

.background_status_icon {
  width: 110px;
  height: 110px;
  margin-top: 24px;
  margin-left: 26px;
  // margin: 24px 32px 0 26px;
  padding: 12px;
  border-radius: 16.8px;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.green-style {
  color: #00c48c;
  background-color: rgba(0, 196, 140, 0.08);
}

.orange-style {
  color: #feb04b;
  background-color: rgba(254, 174, 70, 0.08);
}

.blue-style {
  color: #0076ff;
  background-color: #ebf4ff;
}

.red-style {
  color: #c40000;
  background-color: #fbebeb;
}

.black-style {
  color: #222934;
  background-color: #eeeeef;
}

.purple-style {
  color: #5743af;
  background-color: #f2f0f9;
}

.view-button {
  width: 150px;
  height: 45px;
  margin: 0 0 11px 15px;
  border-radius: 9px;
  border: solid 1px #5743af;
}
.view-button.view-button-margin {
  margin: 50px 0 11px 15px;
}
.view-button-mobile {
  width: 140px;
  height: 50px;
  margin: 19px 0 0 1px;
  padding: 11px 15px 14px 15px;
  border-radius: 9px;
  border: solid 1px #5743af;
}

.back-to-ride-control-button {
  width: 110px !important;
  height: 12px !important;
  margin-left: -10px !important;
  font-size: 12px !important;
}

.mark-as-stored-control-button {
  width: 125px !important;
  height: 12px !important;
  margin-left: -8px !important;
  font-size: 12px !important;
}

@include media('>tablet', '<desktop') {
  .policy-number-rectangle {
    width: 250px;
  }
  .devider-style {
    width: 320px;
  }
}

@include media('>phone', '<=tablet') {
  .policy-number-rectangle {
    width: 250px;
  }
  .devider-style {
    width: 300px;
  }
}

@include media('<tablet') {
  .policy-card-item-button {
    height: 30px;
    // width: 100%;
    padding: 10px;
    text-align: center;
    margin: 20px;
    border-radius: 100px !important;
    border: solid 1px $blue;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .limited-text-200 {
    max-width: 210px;
  }

  .policy-list-card-title {
    max-width: calc(100% - 80px);
    min-width: 0px;
  }
}
@media (max-width: 959px) {
  .policy-card-list-shield {
    margin-right: 15px;
  }
}

@media (max-width: 320px) {
  .green_button {
    width: 200px;
  }

  .policy-number-rectangle {
    width: 160;
  }
}

@media (max-width: 280px) {
  .green_button {
    width: 170px;
  }

  .policy-number-rectangle {
    width: 130px;
  }
}
